import React, { useState, useEffect, useMemo } from "react";

import { Grid, InputAdornment } from "@mui/material";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { TextField } from "@aclymatepackages/atoms";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";

import SpendBasedVendorInputs from "../vendors/SpendBasedVendorInputs";

import { findVendorEmissionCategoryData } from "../../../helpers/components/vendors";

const SpendBasedInputBlock = ({
  value,
  setValue,
  isValueDisabled,
  areNaicsInputsDisabled,
  editVendor,
  vendorCategoryData,
  knownVendorNaicsTitle,
  originalNaicsTitle,
}) => {
  return (
    <Grid item container spacing={2} direction="column">
      <Grid item>
        <TextField
          value={numbersRegExpTest(value) ? Math.abs(value) : value}
          setValue={setValue}
          label="Dollar Value"
          InputProps={{ startAdornment: <InputAdornment>$</InputAdornment> }}
          disabled={isValueDisabled}
        />
      </Grid>
      <SpendBasedVendorInputs
        editVendor={editVendor}
        disabled={areNaicsInputsDisabled}
        questionText="What did you buy and where did you buy it?"
        overrideNaicsTitle={knownVendorNaicsTitle}
        {...vendorCategoryData}
      />
    </Grid>
  );
};

const useSpendBasedInput = ({ transaction = {}, onSave }) => {
  const {
    value: existingValue = 0,
    knownVendor,
    source,
    naicsCode: transactionNaicsCode,
    naicsTitle: transactionNaicsTitle,
    tonsCo2ePerDollar: transactionTonsCo2ePerDollar,
    scopeThreeCategory: transactionScopeThreeCategory,
  } = transaction;

  const {
    naicsCode: knownVendorNaicsCode = "",
    naicsTitle: knownVendorNaicsTitle,
    tonsCo2ePerDollar: knownVendorCo2PerDollar,
    scopeThreeCategory: knownVendorScopeThreeCategory,
  } = knownVendor || {};

  const initialVendorCategoryData = useMemo(
    () => ({
      naicsCode: knownVendorNaicsCode || transactionNaicsCode,
      naicsTitle: knownVendorNaicsTitle || transactionNaicsTitle,
      tonsCo2ePerDollar:
        knownVendorCo2PerDollar || transactionTonsCo2ePerDollar,
      scopeThreeCategory:
        knownVendorScopeThreeCategory || transactionScopeThreeCategory,
    }),
    [
      knownVendorNaicsCode,
      transactionNaicsCode,
      knownVendorNaicsTitle,
      transactionNaicsTitle,
      knownVendorCo2PerDollar,
      transactionTonsCo2ePerDollar,
      knownVendorScopeThreeCategory,
      transactionScopeThreeCategory,
    ]
  );
  const initialScopeThreeCategoryValue =
    knownVendorScopeThreeCategory || transactionScopeThreeCategory;

  const [value, setValue] = useState(existingValue);
  const [vendorCategoryData, setVendorCategoryData] = useState(
    initialVendorCategoryData
  );

  const editVendorCategoryData = (field) => (value) =>
    editObjectData(setVendorCategoryData, field, value);

  useEffect(() => {
    if (knownVendor && Object.keys(knownVendor).length > 3) {
      //This is a little weird, but we only want to set the vendor category data if the known vendor is selected from the dropfown menu, not if it's stored in the transaction (in which case it's only stored with 3 properties)
      setVendorCategoryData(findVendorEmissionCategoryData(knownVendor));
    }
  }, [knownVendor, initialVendorCategoryData, initialScopeThreeCategoryValue]);

  const { naicsTitle, naicsCode, tonsCo2ePerDollar, scopeThreeCategory } =
    vendorCategoryData || {};

  const tonsCo2e = Math.abs(value * tonsCo2ePerDollar);

  const onTransactionSave = () =>
    onSave({
      naicsCode,
      naicsTitle,
      tonsCo2e,
      tonsCo2ePerDollar,
      value,
      scopeThreeCategory,
    });
  const saveEnabled =
    naicsCode && scopeThreeCategory && value && numbersRegExpTest(value);

  const isValueDisabled = source !== "manual-entry" && !!knownVendor;

  const inputBlock = (
    <SpendBasedInputBlock
      value={value}
      setValue={setValue}
      isValueDisabled={isValueDisabled}
      areNaicsInputsDisabled={!!knownVendor}
      editVendor={editVendorCategoryData}
      vendorCategoryData={vendorCategoryData}
      knownVendorNaicsTitle={knownVendorNaicsTitle}
      originalNaicsTitle={transactionNaicsTitle}
    />
  );

  return {
    inputBlock,
    onTransactionSave,
    saveEnabled,
    tonsCo2e,
  };
};
export default useSpendBasedInput;

import React from "react";

import PdlCompanySelect from "../PdlCompanySelect";

const VendorPdlSelect = ({ editVendor }) => {
  const editCompanyName = (name) => {
    editVendor("emissionCategory")("spend-based");
    return editVendor("name")(name);
  };

  const onCompanySelected = (selectedCompany) => {
    const { dbId, pdlId, name } = selectedCompany || {};
    editVendor("name")(name);

    if (dbId) {
      return editVendor("dbId")(dbId);
    }

    editVendor("emissionCategory")("spend-based");
    return editVendor("pdlId")(pdlId);
  };

  return (
    <PdlCompanySelect
      label="Please enter the name of your vendor"
      onCompanyNotFound={editCompanyName}
      onCompanySelected={onCompanySelected}
    />
  );
};
export default VendorPdlSelect;

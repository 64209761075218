import React from "react";

import { Select, CategoriesAvatar } from "@aclymatepackages/atoms";
import { subcategoriesWithColors } from "@aclymatepackages/subcategories";

const EmissionsCategorySelect = ({
  value = "",
  setValue,
  size = "small",
  label = "Select an emissions category",
  multiple,
  disabled,
}) => {
  const options = subcategoriesWithColors
    .filter(({ tags = [] }) => tags.includes("vendors"))
    .map(({ name, subcategory, color }) => {
      return {
        label: name,
        value: subcategory,
        avatar: <CategoriesAvatar size="small" subcategory={subcategory} />,
        style: { backgroundColor: color, color: "white" },
      };
    });

  return (
    <Select
      label={label}
      options={options}
      value={value}
      editValue={setValue}
      size={size}
      valueAvatar={<CategoriesAvatar size="small" subcategory={value} />}
      multiple={multiple}
      labelWidth={250}
      style={{ minWidth: "200px" }}
      disabled={disabled}
    />
  );
};
export default EmissionsCategorySelect;

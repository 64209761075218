import React, { useState } from "react";

import {
  editRowData,
  addDataRow,
} from "@aclymatepackages/array-immutability-helpers";

import ScopeThreeCategorySelect from "./ScopeThreeCategorySelect";
import EmissionsCategorySelect from "./EmissionsCategorySelect";
import OpenAiVendorCategorization from "./OpenAiVendorCategorization";

import VendorPdlSelect from "./VendorPdlSelect";
import MultiPartFormLayout from "../flows/MultipartForm";
import AddNewFormQuestion from "../forms/AddNewFormQuestion";
import useTransactionsUploader from "../../hooks/transactionsCsvUploader";

import { analyticsTrack } from "../../../helpers/analytics";
import { newVendorRelationship } from "../../../helpers/utils/apiCalls";

const VendorInputForm = ({ setOpen, isSliderLeftContent }) => {
  const [transactionsCsvUploaderSteps] = useTransactionsUploader({
    open: true,
    setOpen,
  });

  const [newVendors, setNewVendors] = useState([{}]);
  const [selectedOption, setSelectedOption] = useState("");

  const addNewVendor = () => addDataRow(setNewVendors, {});

  const buildNewVendorInputRows = ({ vendor, editVendor }) => {
    const { name, naicsCode, scopeThreeCategory, emissionCategory, dbId } =
      vendor;

    const editVendorField = (field) => (value) => editVendor(field, value);

    const vendorNameInputRow = {
      value: name,
      input: name ? <></> : <VendorPdlSelect editVendor={editVendorField} />,
    };

    const emissionsCategorySelectStep = !dbId
      ? [
          {
            label: "How are emissions from this vendor categorized?",
            value: emissionCategory,
            input: (
              <EmissionsCategorySelect
                value={emissionCategory}
                setValue={editVendorField("emissionCategory")}
              />
            ),
          },
        ]
      : [];

    const spendBasedInputs =
      emissionCategory === "spend-based"
        ? [
            {
              input: (
                <OpenAiVendorCategorization
                  editVendor={editVendorField}
                  naicsCode={naicsCode}
                />
              ),
              value: naicsCode,
              label: "What does this vendor do?",
            },
            {
              input: (
                <ScopeThreeCategorySelect
                  scopeThreeCategory={scopeThreeCategory}
                  setScopeThreeCategory={editVendorField("scopeThreeCategory")}
                />
              ),
              value: scopeThreeCategory,
              label: "Choose a scope 3 category for this vendor",
            },
          ]
        : [];

    return [
      vendorNameInputRow,
      ...emissionsCategorySelectStep,
      ...spendBasedInputs,
    ];
  };

  const onFormSubmit = async () => {
    await Promise.all(
      newVendors.map(async (vendor) => await newVendorRelationship(vendor))
    );

    if (setOpen) {
      setOpen(false);
    }

    return analyticsTrack("Added Vendors Manually");
  };

  const FormBottomInput = ({ showNextForm }) => (
    <AddNewFormQuestion
      showNextForm={showNextForm}
      onAddNewForm={addNewVendor}
      onFormSubmit={onFormSubmit}
      label="vendor"
    />
  );

  const newVendorsForm = newVendors.map((vendor, idx) => {
    const { name } = vendor;

    return {
      label: name,
      title: name ? `You're adding ${name} as a vendor` : "Select a vendor",
      rows: buildNewVendorInputRows({
        vendor,
        editVendor: editRowData(idx, setNewVendors),
      }),
      FormBottomInput,
    };
  });

  const forms = {
    add: newVendorsForm,
    transactionsCsv: transactionsCsvUploaderSteps,
  };

  return (
    <MultiPartFormLayout
      type="vendors"
      setOption={setSelectedOption}
      selectedOption={selectedOption}
      forms={forms[selectedOption]}
      onClose={!isSliderLeftContent && (() => setOpen(false))}
      submitLoadingText="Please wait while we load your new vendors..."
      style={isSliderLeftContent && { right: "40%" }}
    />
  );
};
export default VendorInputForm;
